
  import Component from 'vue-class-component'
  import { BaseChart } from '../baseChart'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'

@Component({})
  export default class AccumulatedInspections extends BaseChart {
  @Prop() inspections
  @Prop() prevMonthInspections
  @Prop() dates
  @Prop({ default: undefined, type: Number }) idExecutive

  get limitDates () {
    const currentDate = dayjs()
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      return {
        start: this.dates.start,
        end: currentDate.format('YYYY-MM-DD'),
      }
    } else return this.dates
  }

  findLastNotNull (arr: []) {
    const filteredNotNulls = arr.filter(item => item !== null)
    return filteredNotNulls[filteredNotNulls.length - 1]
  }

  getInspectionsCount () {
    if (!this.inspections?.getInspectionStatusReportByDate) return 0
    if (this.idExecutive) {
      return this.inspections.getInspectionStatusReportByDate?.filter(inspection => inspection.executiveId === this.idExecutive).length
    }
    return this.inspections.getInspectionStatusReportByDate?.length
  }

  generateAccumulated (arr: number[]) {
    const accumulated: number[] = []
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) accumulated.push(arr[0])
      else {
        accumulated.push(arr[i] + accumulated[i - 1])
      }
    }
    return accumulated
  }

  byExecutive (inspection) {
    if (this.idExecutive) {
      return inspection.executiveId === this.idExecutive
    }
    return true
  }

  getData () {
    if (
      !this.inspections?.getInspectionStatusReportByDate ||
      !this.prevMonthInspections?.getInspectionStatusReportByDate) return

    const inspections = {
      currentMonth: this.inspections.getInspectionStatusReportByDate.filter(this.byExecutive),
      prevMonth: this.prevMonthInspections.getInspectionStatusReportByDate.filter(this.byExecutive),
    }

    const limitDateRange = this.generateDayRange(
      dayjs(this.limitDates.start),
      dayjs(this.limitDates.end),
    )

    const prevMonthRange = this.generateDayRange(
      dayjs(this.limitDates.start).subtract(1, 'month'),
      dayjs(this.limitDates.start).subtract(1, 'month').endOf('month')
    ).slice(0, this.generateDayRange(dayjs(this.limitDates.start), dayjs(this.limitDates.start).endOf('month')).length - 1)

    const categories = this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    ).slice(0, -1)

    const currentMonth = {
      canceledInspections: inspections.currentMonth.filter(inspection => {
        return inspection.closingReason?.description === 'Cancelada'
      }),
      validatedInspections: inspections.currentMonth.filter(inspection => {
        return inspection.supervisorQualification
      }),
      finishedInspections: inspections.currentMonth.filter(inspection => {
        return inspection.inspectorQualification
      }),
    }

    const prevMonth = {
      canceledInspections: inspections.prevMonth.filter(inspection => {
        return inspection.closingReason?.description === 'Cancelada'
      }),
      validatedInspections: inspections.prevMonth.filter(inspection => {
        return inspection.supervisorQualification
      }),
      finishedInspections: inspections.prevMonth.filter(inspection => {
        return inspection.inspectorQualification
      }),
    }

    const purchasedInspections = {
      currentMonth: inspections.currentMonth.filter(inspection => inspection.bought),
      prevMonth: inspections.prevMonth.filter(inspection => inspection.bought),
    }

    const count = {
      currentMonth: {
        scheduled: limitDateRange.map(category => {
          const filteredByDate = inspections.currentMonth.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        canceled: limitDateRange.map(category => {
          const filteredByDate = currentMonth.canceledInspections.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        validated: limitDateRange.map(category => {
          const filteredByDate = currentMonth.validatedInspections.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        successful: limitDateRange.map(category => {
          const filteredByDate = currentMonth.finishedInspections.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        purchases: limitDateRange.map(category => {
          const filteredByDate = purchasedInspections.currentMonth.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
      },
      prevMonth: {
        scheduled: prevMonthRange.map(category => {
          const filteredByDate = inspections.prevMonth.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        canceled: prevMonthRange.map(category => {
          const filteredByDate = prevMonth.canceledInspections.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        validated: prevMonthRange.map(category => {
          const filteredByDate = prevMonth.validatedInspections.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        successful: prevMonthRange.map(category => {
          const filteredByDate = prevMonth.finishedInspections.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
        purchases: prevMonthRange.map(category => {
          const filteredByDate = purchasedInspections.prevMonth.filter(inspection => {
            return dayjs(category).isSame(dayjs(this.convertUtc(inspection.createdAt)), 'day')
          })
          return filteredByDate.length
        }),
      },
    }

    const accumulateds = {
      currentMonth: {
        scheduled: this.generateAccumulated(count.currentMonth.scheduled),
        canceled: this.generateAccumulated(count.currentMonth.canceled),
        validated: this.generateAccumulated(count.currentMonth.validated),
        successful: this.generateAccumulated(count.currentMonth.successful),
        purchases: this.generateAccumulated(count.currentMonth.purchases),
      },
      prevMonth: {
        scheduled: this.generateAccumulated(count.prevMonth.scheduled),
        canceled: this.generateAccumulated(count.prevMonth.canceled),
        validated: this.generateAccumulated(count.prevMonth.validated),
        successful: this.generateAccumulated(count.prevMonth.successful),
        purchases: this.generateAccumulated(count.prevMonth.purchases),
      },
    }

    for (let i = limitDateRange.length; i < categories.length; i++) {
      accumulateds.currentMonth.scheduled.push(null)
      accumulateds.currentMonth.canceled.push(null)
      accumulateds.currentMonth.validated.push(null)
      accumulateds.currentMonth.successful.push(null)
      accumulateds.currentMonth.purchases.push(null)
    }

    const mondays = []
    categories.forEach(category => {
      if (category.day() === 1) {
        mondays.push(category)
      }
    })

    const annotations = mondays.map(monday => {
      return {
        x: monday.format('DD'),
        borderColor: '#FF0000',
      }
    })

    this.series = [
      { name: 'Agendadas', data: accumulateds.currentMonth.scheduled, color: '#FAD901' },
      { name: 'Canceladas', data: accumulateds.currentMonth.canceled, color: '#F03738' },
      { name: 'Finalizadas', data: accumulateds.currentMonth.successful, color: '#13CE66' },
      { name: 'Validadas', data: accumulateds.currentMonth.validated, color: '#7D67BC' },
      { name: 'Compradas', data: accumulateds.currentMonth.purchases, color: '#6185DB' },
      { name: 'Agendadas (mes anterior)', data: accumulateds.prevMonth.scheduled, color: '#FAD90133' },
      { name: 'Canceladas (mes anterior)', data: accumulateds.prevMonth.canceled, color: '#F0373833' },
      { name: 'Finalizadas (mes anterior)', data: accumulateds.prevMonth.successful, color: '#13CE6633' },
      { name: 'Validadas (mes anterior)', data: accumulateds.prevMonth.validated, color: '#7D67BC33' },
      { name: 'Compradas (mes anterior)', data: accumulateds.prevMonth.purchases, color: '#6185DB33' },
    ]

    for (let i = 0; i < this.series.length; i++) {
      this.series[i].data = this.series[i].data.slice(0, categories.length)
    }

    this.chartOptions = {
      labels: categories,
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      annotations: {
        xaxis: annotations,
      },
      stroke: {
        width: 3,
        curve: 'straight',
        dashArray: [0, 0, 0, 0, 0, 3, 3, 3, 3, 3],
      },
      markers: {
        size: 5,
      },
      xaxis: {
        categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD')
          },
        },
      },
      yaxis: {
        stepSize: 2,
        labels: {
          formatter (val) {
            return Math.floor(val)
          },
        },
      },
    }
  }

  get inspectionsNumber () {
    if (this.inspections.getInspectionStatusReportByDate) {
      return this.inspections.getInspectionStatusReportByDate.length
    } else {
      return 0
    }
  }

  @Watch('inspections', { immediate: false, deep: true })
  @Watch('prevMonthInspections', { immediate: false, deep: true })
  @Watch('dates', { immediate: false, deep: true })
  update () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
