
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import { DetailChart } from './detailChart'
  import CarDetail from './Detail/CarDetail.vue'

@Component({
  components: {
    CarDetail,
  },
})
  export default class DetailCreditsByCar extends DetailChart {
  @Prop() credits: Record<string, any>
  @Prop() creditsTarget: Record<string, any>
  @Prop({ default: undefined, type: Number }) executives

  amount = 0
  target = 10
  cars = []

  // Methods
  getData () {
    if (
      !this.credits?.loans_evaluation_view_aggregate ||
      !this.creditsTarget?.settings_process) return
    this.cars = this.credits?.loans_evaluation_view_aggregate?.nodes
    this.amount = this.cars.length
    const creditsTarget = Math.round((this.creditsTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity / (this.executives || 1)) * (this.executives ? 1.2 : 1))
    if (creditsTarget) {
      this.target = creditsTarget
    }
  }

  @Watch('credits', { immediate: true, deep: true })
  @Watch('creditsTarget', { immediate: true, deep: true })
  @Watch('executives', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
