
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { forwarderColors } from '@/utils/forwarderColors'
  import { channelColors } from '@/utils/channelColors'

@Component({})
  export default class LeadsByForwarderPie extends BaseChart {
  @Prop() leads: Record<string, any>
  @Prop({ type: Boolean, default: true }) showTitle
  colors = []
  labels = []

  getChannelByForwarder (forwarder) {
    const channels = []
    let byForwarder = []
    if (forwarder === 'GENIO') {
      byForwarder = this.leads.records.filter(lead => {
        return lead.created_by?.['x-hasura-user'] === 'system' &&
          !lead.id_forwarder
      })
    }
    if (forwarder === 'Prospección') {
      byForwarder = this.leads.records.filter(lead => {
        return lead.created_by?.['x-hasura-user-email'] === lead.executive?.person?.user?.email &&
          !lead.id_forwarder
      })
    }
    if (forwarder === 'Derivador') {
      byForwarder = this.leads.records.filter(lead => {
        return lead.id_forwarder
      })
    }
    if (forwarder === 'Sin ejecutivo') {
      byForwarder = this.leads.records.filter(lead => {
        return !lead.executive
      })
    }
    byForwarder.forEach(lead => {
      if (!channels.map(channel => channel?.name).includes(lead.deals?.[0]?.channel?.name)) {
        channels.push(lead.deals?.[0]?.channel)
      }
    })
    const leadsByChannel = channels.filter(channel => channel !== undefined).map(channel => {
      return {
        color: channelColors[channel.name],
        name: channel.name,
        description: channel.description,
        leads: byForwarder.filter(lead => lead.deals?.[0]?.channel?.name === channel.name),
      }
    })
    return leadsByChannel.sort((prev, next) => next.leads.length - prev.leads.length)
  }

  // Methods
  getStock () {
    const leads = this.leads?.records
    if (!leads) return

    const chartData = {
      genio: leads.filter(lead => {
        return lead.created_by?.['x-hasura-user'] === 'system' &&
          !lead.id_forwarder
      }),
      forwarded: leads.filter(lead => {
        return lead.id_forwarder
      }),
      prospected: leads.filter(lead => {
        return lead.created_by?.['x-hasura-user-email'] === lead.executive?.person?.user?.email &&
          !lead.id_forwarder
      }),
      noExecutive: leads.filter(lead => {
        return !lead.executive
      }),
    }

    this.series = []
    const options = {
      labels: [],
      colors: [],
    }
    if (chartData.genio?.length > 0) {
      this.series.push(chartData.genio.length)
      options.labels.push('GENIO')
      options.colors.push(forwarderColors.genio)
    }
    if (chartData.forwarded?.length > 0) {
      this.series.push(chartData.forwarded.length)
      options.labels.push('Derivador')
      options.colors.push(forwarderColors.manual)
    }
    if (chartData.prospected?.length > 0) {
      this.series.push(chartData.prospected.length)
      options.labels.push('Prospección')
      options.colors.push(forwarderColors.prospected)
    }
    if (chartData.noExecutive?.length > 0) {
      this.series.push(chartData.noExecutive.length)
      options.labels.push('Sin ejecutivo')
      options.colors.push(forwarderColors.noExecutive)
    }

    this.labels = this.sortSeries(options).labels
    this.colors = this.sortSeries(options).colors

    this.chartOptions = {
      labels: options.labels,
      colors: options.colors,
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        fontSize: '14px',
        fontFamily: 'Poppins',
      },
      title: {
        text: this.showTitle ? ('Por derivador') : undefined,
        style: {
          fontFamily: 'Poppins',
        },
      },
      tooltip: {
        y: {
          formatter (val: number) {
            return val + ' leads'
          },
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            minAngleToShowLabel: 1,
          },
        },
      },
    }
  }

  sortSeries (options) {
    let sortArray = []
    this.series.forEach((item, index) => {
      sortArray.push([item, options.labels[index], options.colors[index]])
    })
    sortArray = sortArray.sort((prev, next) => {
      return next[0] - prev[0]
    })

    this.series = sortArray.map(item => item[0])
    options.labels = sortArray.map(item => item[1])
    options.colors = sortArray.map(item => item[2])

    return options
  }

  // Getters
  get leadCount () {
    if (!this.leads?.records) return 0
    return this.leads?.records?.length
  }

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getStock()
    this.display = true
  }
  }
