
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import StockPieTotal from '@/components/dashboards/StockPieTotal.vue'
  import StockPieCost from '@/components/dashboards/StockPieCost.vue'

  // New Charts
  import LeadsByChannel from '@/components/dashboards/LeadsByChannel.vue'
  import LeadsByPipelinePie from '@/components/dashboards/LeadsByPipelinePie.vue'
  import LeadByExecutive from '@/components/dashboards/LeadByExecutive.vue'
  import LeadsByChannelPie from '@/components/dashboards/LeadsByChannelPie.vue'
  import DealClosingReasonTreeMap from '@/components/dashboards/DealClosingReasonTreeMap.vue'
  import StockLinePrices from '@/components/dashboards/StockLinePrices.vue'
  import { mapActions, mapGetters } from 'vuex'
  import { Role } from '@/store/user/models'
  import { Query } from '@/utils/computed/Query'
  import { formFilter } from '@/graphql/generated-types'
  import { ROLES_DASHBOARD_ALLOWED } from '@/store/user/state'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { fixDate } from '@/utils/general'
  import PeriodSales from '@/components/dashboards/PeriodSales.vue'
  import PeriodCredits from '@/components/dashboards/PeriodCredits.vue'
  import UtilityPeriodCredits from '@/components/dashboards/UtilityPeriodCredits.vue'
  import UtilityPeriodSales from '@/components/dashboards/UtilityPeriodSales.vue'
  import UtilityPeriodTotal from '@/components/dashboards/UtilityPeriodTotal.vue'
  import PeriodPurchases from '@/components/dashboards/PeriodPurchases.vue'
  import DetailSalesByCar from '@/components/dashboards/DetailSalesByCar.vue'
  import DetailPurchasesByCar from '@/components/dashboards/DetailPurchasesByCar.vue'
  import DetailCreditsByCar from '@/components/dashboards/DetailCreditsByCar.vue'
  import SalesByExecutive from '@/components/dashboards/SalesByExecutive.vue'
  import PurchasesByExecutive from '@/components/dashboards/PurchasesByExecutive.vue'
  import CreditsByExecutive from '@/components/dashboards/CreditsByExecutive.vue'
  import LoanApplications from '@/components/dashboards/LoanApplications.vue'
  import SignedLoans from '@/components/dashboards/SignedLoans.vue'
  import Accumulateds from '@/components/dashboards/Accumulateds.vue'
  import PurchasesVsSales from '@/components/dashboards/PurchasesVsSales.vue'
  import LeadsByPipeline from '@/components/dashboards/LeadsByPipeline.vue'
  import LeadsByForwarder from '@/components/dashboards/LeadsByForwarder.vue'
  import DailyStock from '@/components/dashboards/DailyStock.vue'
  import DailyStockValue from '@/components/dashboards/DailyStockValue.vue'
  import SalesByChannelPie from '@/components/dashboards/SalesByChannelPie.vue'
  import CreditsByChannelPie from '@/components/dashboards/CreditsByChannel.vue'
  import PurchasesByChannelPie from '@/components/dashboards/PurchasesByChannel.vue'
  import LeadsByForwarderPie from '@/components/dashboards/LeadsByForwarderPie.vue'
  import CarsByChassis from '@/components/dashboards/CarsByChassis.vue'
  import CarsByFuel from '@/components/dashboards/CarsByFuel.vue'
  import CarsByTransmission from '@/components/dashboards/CarsByTransmission.vue'
  import FinancialEvaluationReport from '@/components/dashboards/Reports/FinancialEvaluation.vue'
  import CarsByPrice from '@/components/dashboards/CarsByPrice.vue'
  import InspectionsReport from '@/components/dashboards/Reports/Inspections.vue'
  import ReserveReport from '@/components/dashboards/Reports/Reserve.vue'
  import SalesReport from '@/components/dashboards/Reports/Sales.vue'
  import CreditsReport from '@/components/dashboards/Reports/Credits.vue'
  import Scheduled from '@/components/dashboards/InspectionStatus/Scheduled.vue'
  import LeadsByTask from '@/components/dashboards/LeadsByTask.vue'
  import { filters } from '@/components/dashboards/filters'
  import PurchasesReport from '@/components/dashboards/Reports/Purchases.vue'
  import Finished from '@/components/dashboards/InspectionStatus/Finished.vue'
  import Canceled from '@/components/dashboards/InspectionStatus/Canceled.vue'
  import AccumulatedInspections from '@/components/dashboards/Inspections/AccumulatedInspections.vue'
  import StaffDashboard from '@/components/dashboards/Roles/staff/staff.vue'
  import TwelveMonthGeneralDetail from '@/components/dashboards/TwelveMonthGeneralDetail.vue'

@Component({
  components: {
    StaffDashboard,
    GDatePicker,
    StockLinePrices,
    DealClosingReasonTreeMap,
    LeadsByChannelPie,
    LeadByExecutive,
    LeadsByPipelinePie,
    StockPieCost,
    StockPieTotal,
    LeadsByChannel,
    PeriodPurchases,
    PeriodSales,
    PeriodCredits,
    UtilityPeriodCredits,
    UtilityPeriodSales,
    UtilityPeriodTotal,
    DetailSalesByCar,
    DetailPurchasesByCar,
    DetailCreditsByCar,
    SalesByExecutive,
    PurchasesByExecutive,
    CreditsByExecutive,
    LoanApplications,
    SignedLoans,
    Accumulateds,
    PurchasesVsSales,
    LeadsByPipeline,
    LeadsByForwarder,
    DailyStock,
    DailyStockValue,
    SalesByChannelPie,
    CreditsByChannelPie,
    PurchasesByChannelPie,
    LeadsByForwarderPie,
    CarsByChassis,
    CarsByFuel,
    CarsByTransmission,
    CarsByPrice,
    FinancialEvaluationReport,
    InspectionsReport,
    ReserveReport,
    SalesReport,
    CreditsReport,
    PurchasesReport,
    Scheduled,
    LeadsByTask,
    Finished,
    Canceled,
    AccumulatedInspections,
    TwelveMonthGeneralDetail,
  },
  methods: {
    fixDate,
    ...mapActions('resources/form', ['fetchData']),
    ...mapActions('dashboard', [
      'getPurchasesAmount',
      'getPurchasesTarget',
      'getSalesByDay',
      'getSalesAmount',
      'getSalesTarget',
      'getCreditsByDay',
      'getCreditsTarget',
      'getUtilityCreditsByDay',
      'getStockStatus',
      'getStockPrices',
      'getLeadByExecutive',
      'getDealsByChannel',
      'getClosingReason',
      'getLoanEvaluations',
      'getSignedLoans',
      'getFinancing',
      'getExecutivesForReports',
      'getLeads',
      'getDailyStock',
      'getStockSpecs',
      'getFinancialReports',
      'getInspectionReports',
      'getReserveReports',
      'getSalesReports',
      'getCreditsReports',
      'getPurchasesReports',
      'getInspectionsStatus',
      'getInspectionsSettings',
      'getSalesData',
    ]),
  },
  computed: {
    ...mapGetters('user', ['roles']),
    ...mapGetters('app', ['isMobile']),

  },
})
  export default class Home extends Vue {
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  getPurchasesAmount!: (variable) => Promise<Record<string, any>>
  getPurchasesTarget!: () => Promise<Record<string, any>>
  getSalesByDay!: (variable) => Promise<Record<string, any>>
  getSalesTarget!: () => Promise<Record<string, any>>
  getCreditsByDay!: (variable) => Promise<Record<string, any>>
  getCreditsTarget!: () => Promise<Record<string, any>>
  getSalesAmount!: (variable) => Promise<Record<string, any>>
  getUtilityCreditsByDay!: (variable) => Promise<Record<string, any>>
  getStockStatus!: (variable) => Promise<Record<string, any>>
  getStockPrices!: (variable) => Promise<Record<string, any>>
  getLeadByExecutive!: (variable) => Promise<Record<string, any>>
  getDealsByChannel!: (variable) => Promise<Record<string, any>>
  getClosingReason!: (variable) => Promise<Record<string, any>>
  getLoanEvaluations!: (variable) => Promise<Record<string, any>>
  getSignedLoans!: (variable) => Promise<Record<string, any>>
  getFinancing!: (variable) => Promise<Record<string, any>>
  getExecutivesForReports!: () => Promise<Record<string, any>>
  getLeads!: (variable) => Promise<Record<string, any>>
  getDailyStock!: (variable) => Promise<Record<string, any>>
  getStockSpecs!: (variable) => Promise<Record<string, any>>
  getFinancialReports!: () => Promise<Record<string, any>>
  getInspectionReports!: () => Promise<Record<string, any>>
  getReserveReports!: () => Promise<Record<string, any>>
  getSalesReports!: () => Promise<Record<string, any>>
  getCreditsReports!: () => Promise<Record<string, any>>
  getPurchasesReports!: () => Promise<Record<string, any>>
  getInspectionsSettings!: () => Promise<Record<string, any>>
  getInspectionsStatus!: (variable) => Promise<Record<string, any>>
  getSalesData!: (variable) => Promise<Record<string, any>>

  isMobile!: boolean
  roles!: Array<Role>
  menu = false
  displayCharts = false
  displayStaff = false
  loading = false
  img = require('@/assets/genio/GENIO_welcome.svg')
  currentDate = dayjs()
  endOfMonth = this.currentDate.endOf('month')
  daysTillMonthEnd = this.endOfMonth
    .diff(this.currentDate, 'days')

  monthLenght = this.endOfMonth
    .diff(this.currentDate.startOf('month'), 'days') + 1

  monthPercentage = Math.round((this.currentDate.$D / this.monthLenght) * 100)
  selected = new Date(this.currentDate.format('YYYY/MM/DD')).toISOString().substring(0, 7)

  created () {
    this.currentDate = dayjs()
  }

  calledResults = false

  async getResultsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledResults) {
      return
    }
    this.loading = true
    this.$set(this.chartData, 'soldSalesAmount', await this.getSalesAmount(this.filters.soldSales))
    this.$set(this.chartData, 'purchasesAmount', await this.getPurchasesAmount(this.filters.stockCreatedDate))
    this.$set(this.chartData, 'purchasesTarget', await this.getPurchasesTarget())
    this.$set(this.chartData, 'soldPurchasesAmount', await this.getPurchasesAmount(this.filters.soldPurchases))
    this.$set(this.chartData, 'sales', await this.getSalesByDay(this.filters.soldDate))
    this.$set(this.chartData, 'salesData', await this.getSalesData(this.filters.sales))
    this.$set(this.chartData, 'salesTarget', await this.getSalesTarget())
    this.$set(this.chartData, 'credits', await this.getCreditsByDay(this.filters.credit))
    this.$set(this.chartData, 'creditTarget', await this.getCreditsTarget())
    this.$set(this.chartData, 'salesAmount', await this.getSalesAmount(this.filters.salesAmount))
    this.$set(this.chartData, 'utilityCreditsByDay', await this.getUtilityCreditsByDay(this.filters.utilityCredits))
    this.loading = false

    this.calledResults = true
  }

  calledTwelveMonthsResults = false

  async getTwelveMonthsResults (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledTwelveMonthsResults) {
      return
    }
    this.$set(this.twelveMonthsData, 'purchases', await this.getPurchasesAmount(this.twelveMonthsFilters.stockCreatedDate))
    this.$set(this.twelveMonthsData, 'sales', await this.getSalesData(this.twelveMonthsFilters.sales))
    this.$set(this.twelveMonthsData, 'salesAmount', await this.getSalesByDay(this.twelveMonthsFilters.soldDate))
    this.$set(this.twelveMonthsData, 'credits', await this.getCreditsByDay(this.twelveMonthsFilters.credit))
    this.calledTwelveMonthsResults = true
  }

  calledAccumulateds = false

  async getAccumulateds (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledAccumulateds) {
      return
    }
    this.$set(this.prevMonthData, 'sales', await this.getSalesByDay(this.prevMonthFilters.soldDate))
    this.$set(this.prevMonthData, 'purchases', await this.getPurchasesAmount(this.prevMonthFilters.stockCreatedDate))
    this.$set(this.prevMonthData, 'credits', await this.getCreditsByDay(this.prevMonthFilters.credit))

    this.calledAccumulateds = true
  }

  calledReports = false

  async getReportsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledReports) {
      return
    }
    this.$set(this.chartData, 'reports', await this.getFinancialReports())
    this.$set(this.chartData, 'inspectionsReports', await this.getInspectionReports())
    this.$set(this.chartData, 'reserveReports', await this.getReserveReports())
    this.$set(this.chartData, 'salesReports', await this.getSalesReports())
    this.$set(this.chartData, 'creditsReports', await this.getCreditsReports())
    this.$set(this.chartData, 'purchasesReports', await this.getPurchasesReports())
    this.calledReports = true
  }

  calledStockData = false

  async getStockData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledStockData) {
      return
    }
    this.$set(this.chartData, 'stockStatus', await this.getStockStatus(this.filters.stock))
    this.$set(this.chartData, 'stockPrices', await this.getStockPrices(this.filters.stock))
    this.calledStockData = true
  }

  calledVehicleData = false

  async getVehicleData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledVehicleData) {
      return
    }
    this.$set(this.chartData, 'stockSpecs', await this.getStockSpecs(this.filters.stockSpecs))

    const specs = await Promise.all(this.chartData.stockSpecs.salesStockView.map(async item => {
      return this.findAlternativesAttributes(item.auto)
    }))

    this.$set(this.chartData, 'carsByChassis', specs.map(car =>
      car.filter(spec => spec.component.slug === 'bodywork')[0]
    ))

    this.$set(this.chartData, 'carsByFuel', specs.map(car =>
      car.filter(spec => spec.component.slug === 'fuel')[0]
    ))

    this.$set(this.chartData, 'carsByTransmission', specs.map(car =>
      car.filter(spec => spec.component.slug === 'transmission')[0]
    ))

    this.calledVehicleData = true
  }

  calledStockQuantity = false

  async getStockQuantityData (_entries, _object, isIntersecting) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledStockQuantity) {
      return
    }
    this.$set(this.prevMonthData, 'dailyStock', await this.getDailyStock(this.prevMonthFilters.dailyStock))
    this.$set(this.chartData, 'dailyStock', await this.getDailyStock(this.filters.dailyStock))
    this.calledStockQuantity = true
  }

  calledFinancing = false

  async getFinancingData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledFinancing) {
      return
    }
    this.$set(this.chartData, 'signedLoans', await this.getSignedLoans(this.filters.signedLoans))
    this.$set(this.chartData, 'evaluations', await this.getFinancing(this.filters.loanApplications))
    this.calledFinancing = true
  }

  calledInspectionsData = false

  async getInspectionsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledInspectionsData) {
      return
    }
    this.$set(this.chartData, 'inspectionsStatus', await this.getInspectionsStatus(this.filters.inspectionsStatus))
    this.$set(this.prevMonthData, 'inspectionsStatus', await this.getInspectionsStatus(this.prevMonthFilters.inspectionsStatus))
    this.$set(this.chartData, 'inspectionsSettings', await this.getInspectionsSettings())
    this.calledInspectionsData = true
  }

  calledLeadsData = false

  async getLeadsData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledLeadsData) {
      return
    }
    this.$set(this.prevMonthData, 'leads', await this.getLeads(this.prevMonthFilters.leads))
    this.$set(this.chartData, 'leads', await this.getLeads(this.filters.leads))
    this.calledLeadsData = true
  }

  calledClosingReasonData = false

  async getClosingReasonData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledClosingReasonData) {
      return
    }
    this.$set(this.chartData, 'closingReason', await this.getClosingReason(this.filters.closingReason))
    this.calledClosingReasonData = true
  }

  calledDetailedData = false

  async getDetailedData (_entries?, _observer?, isIntersecting?) {
    if (!isIntersecting || !this.roles.some(role => ROLES_DASHBOARD_ALLOWED[role.slug]) || this.calledDetailedData) {
      return
    }
    this.$set(this.chartData, 'executivesForReports', await this.getExecutivesForReports())
    this.calledDetailedData = true
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'findLite', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
      force: true,
    })
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    const getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    const generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        _and: [
          { id_version_year: { _eq: auto.version.id } },
          { component: { slug: { _in: ['transmission', 'bodywork', 'fuel'] } } },
        ],
      },
      force: true,
    })

    if (attributes?.length) {
      return attributes
    }
  }

  get formData () {
    return {
      start: this.formatDate(dayjs(this.selected).startOf('month'), 'YYYY-MM-DDTHH:mm:ss'),
      end: this.formatDate(dayjs(this.selected).endOf('month'), 'YYYY-MM-DDTHH:mm:ss'),
    }
  }

  get filters () {
    return filters(this.currentMonth)
  }

  get prevMonthFilters () {
    return filters(this.prevMonth)
  }

  get twelveMonthsFilters () {
    return filters(this.twelveMonths)
  }

  chartData: Record<string, any> = {}
  prevMonthData: Record<string, any> = {}
  twelveMonthsData: Record<string, any> = {}

  get dateString () {
    const day = this.currentDate.format('DD')
    const monthNumber = this.currentDate.format('MM')
    let month
    switch (monthNumber) {
      case '01':
        month = 'Enero'
        break
      case '02':
        month = 'Febrero'
        break
      case '03':
        month = 'Marzo'
        break
      case '04':
        month = 'Abril'
        break
      case '05':
        month = 'Mayo'
        break
      case '06':
        month = 'Junio'
        break
      case '07':
        month = 'Julio'
        break
      case '08':
        month = 'Agosto'
        break
      case '09':
        month = 'Septiembre'
        break
      case '10':
        month = 'Octubre'
        break
      case '11':
        month = 'Noviembre'
        break
      default:
        month = 'Diciembre'
        break
    }
    return `${day} de ${month}`
  }

  get currentMonth () {
    return {
      _gte: dayjs(this.formData.start).format('YYYY-MM-DDTHH:mm:ss'),
      _lte: dayjs(this.formData.end).format('YYYY-MM-DDTHH:mm:ss'),
    }
  }

  get prevMonth () {
    const startPrevMonth = dayjs(this.formData.start).subtract(1, 'month')
    const endPrevMonth = startPrevMonth.endOf('month')

    return {
      _gte: startPrevMonth.format('YYYY-MM-DDTHH:mm:ss'),
      _lte: this.formatDate(endPrevMonth.endOf('day')),
    }
  }

  get twelveMonths () {
    const end = dayjs(this.currentMonth._lte)
    const start = dayjs(this.currentMonth._lte).subtract(1, 'year').startOf('month')

    return {
      _gte: this.formatDate(start),
      _lte: end.format('YYYY-MM-DDTHH:mm:ss'),
    }
  }

  formatDate (date, format = 'YYYY-MM-DDTHH:mm:ss') {
    if (!date?.isValid()) return

    const local = dayjs(date)
    const offset = -dayjs().utcOffset()
    const localDate = local.add(offset, 'minute')

    return localDate.format(format)
  }

  @Watch('roles', { immediate: true, deep: true })
  async onRolesChange (val) {
    if (this.$route.name === 'home') {
      this.displayCharts = val.some(role => ROLES_DASHBOARD_ALLOWED[role.slug])
      if (!this.displayCharts) {
        this.displayStaff = val.some(role => role.slug === 'staff')
      }
    } else {
      this.displayCharts = false
    }
  }

  @Watch('currentMonth', { immediate: true, deep: true })
  async onDateChange () {
    this.calledResults = false
    this.calledAccumulateds = false
    this.calledReports = false
    this.calledStockData = false
    this.calledVehicleData = false
    this.calledStockQuantity = false
    this.calledFinancing = false
    this.calledInspectionsData = false
    this.calledLeadsData = false
    this.calledClosingReasonData = false
    this.calledDetailedData = false
    this.calledTwelveMonthsResults = false

    await this.getResultsData(undefined, undefined, true)
  }

  get width () {
    const { $vuetify } = this

    return $vuetify.breakpoint.width - 25
  }

  get height () {
    const { isMobile } = this
    return isMobile ? 400 : 800
  }
  }
