
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import dayjs from 'dayjs'
  import { forwarderColors } from '@/utils/forwarderColors'

@Component({})
  export default class LeadsByForwarder extends BaseChart {
  @Prop() leads: Record<string, any>
  @Prop() prevMonthLeads: Record<string, any>
  @Prop() dates

  // Methods

  toggleSerie (serie) {
    const notHide = [serie, serie + ' (mes anterior)']
    this.series
      .slice(0, this.series.length / 2)
      .map(serie => serie.name)
      .forEach(serie => {
        if (notHide.includes(serie)) {
          this.$refs.chart.showSeries(serie)
          this.$refs.chart.showSeries(serie + ' (mes anterior)')
        } else {
          this.$refs.chart.hideSeries(serie)
          this.$refs.chart.hideSeries(serie + ' (mes anterior)')
        }
      })
  }

  getData () {
    if (!this.leads || !this.prevMonthLeads) return
    const leads = this.leads.records
    const prevMonthLeads = this.prevMonthLeads.records

    if (!leads || !prevMonthLeads) return
    const categories = this.generateDayRange(
      dayjs(this.dates.start),
      dayjs(this.dates.end)
    ).slice(0, -1)
    const prevMonthRange = this.generateDayRange(
      dayjs(this.dates.start).subtract(1, 'month').startOf('month'),
      dayjs(this.dates.start).subtract(1, 'month').endOf('month')
    ).slice(0,
      this.generateDayRange(dayjs(this.dates.start), dayjs(this.dates.start).endOf('month')).length - 1
    )

    this.series = []
    const currentMonth = {
      genio: leads.filter(lead => {
        return lead.created_by?.['x-hasura-user'] === 'system' &&
          !lead.id_forwarder
      }),
      forwarded: leads.filter(lead => {
        return lead.id_forwarder
      }),
      prospected: leads.filter(lead => {
        return lead.created_by?.['x-hasura-user-email'] === lead.executive?.person?.user?.email &&
          !lead.id_forwarder
      }),
      noExecutive: leads.filter(lead => {
        return !lead.executive
      }),
    }
    const prevMonth = {
      genio: prevMonthLeads.filter(lead => {
        return lead.created_by?.['x-hasura-user'] === 'system' &&
          !lead.id_forwarder
      }),
      forwarded: prevMonthLeads.filter(lead => {
        return lead.id_forwarder
      }),
      prospected: prevMonthLeads.filter(lead => {
        return lead.created_by?.['x-hasura-user-email'] === lead.executive?.perosn?.user?.email &&
          !lead.id_forwarder
      }),
      noExecutive: prevMonthLeads.filter(lead => {
        return !lead.executive
      }),
    }
    this.series.push({
      name: 'GENIO',
      data: categories.map(category => {
        return currentMonth.genio.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.genio,
      type: 'line',
    })
    // Manual
    this.series.push({
      name: 'Manual',
      data: categories.map(category => {
        return currentMonth.forwarded.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.manual,
      type: 'line',
    })
    // Prospeccion
    this.series.push({
      name: 'Prospección',
      data: categories.map(category => {
        return currentMonth.prospected.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.prospected,
      type: 'line',
    })
    // Sin ejecutivo
    this.series.push({
      name: 'Sin ejecutivo',
      data: categories.map(category => {
        return currentMonth.noExecutive.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.noExecutive,
      type: 'line',
    })
    this.series.push({
      name: 'GENIO (mes anterior)',
      data: prevMonthRange.map(category => {
        return prevMonth.genio.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.genio + '4C',
      type: 'line',
    })
    this.series.push({
      name: 'Prospección (mes anterior)',
      data: prevMonthRange.map(category => {
        return prevMonth.prospected.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.prospected + '4C',
      type: 'line',
    })
    this.series.push({
      name: 'Manual (mes anterior)',
      data: prevMonthRange.map(category => {
        return prevMonth.forwarded.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.manual + '4C',
      type: 'line',
    })
    this.series.push({
      name: 'Sin ejecutivo (mes anterior)',
      data: prevMonthRange.map(category => {
        return prevMonth.noExecutive.filter(lead => {
          return dayjs(category).isSame(dayjs(this.convertUtc(lead.created_at)), 'day')
        }).length
      }),
      color: forwarderColors.noExecutive + '4C',
      type: 'line',
    })
    this.series.push({
      name: 'Total',
      data: categories.map(category => {
        return leads.filter(lead => {
          return dayjs(this.convertUtc(lead.created_at)).isSame(dayjs(category), 'day')
        }).length
      }),
      type: 'area',
      color: '#DCFCE7',
    })

    this.series = this.series.filter(serie => serie.data.some(data => data !== 0))

    let maxSum = this.series
      .map(serie => serie.data)
      .flat()
      .sort((a, b) => b - a)[0]

    if (maxSum % 2 !== 0) maxSum++

    this.chartOptions = {
      chart: {
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        categories,
        type: 'category',
        labels: {
          formatter (val) {
            return dayjs(val).format('DD/MM')
          },
        },
      },
      title: {
        text: 'Por derivador',
        style: {
          fontFamily: 'Poppins',
        },
      },
      yaxis: {
        min: 0,
        max: maxSum,
        forceNiceScale: true,
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
      },
    }
  }

  // Getters

  // Watchers
  @Watch('leads', { immediate: true, deep: true })
  @Watch('prevMonthLeads', { immediate: true, deep: true })
  @Watch('dates', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
