import Component from 'vue-class-component'
import { DetailChart } from './detailChart'
import { Prop } from 'vue-property-decorator'

@Component({})
export class DetailByExecutive extends DetailChart {
  @Prop() executivesForReports: Record<string, any>
  cars: Record<string, any>[]
  executives: { name: string, amount: number }[]

  get individualTarget () {
    return (this.target / this.executivesNumber) * 1.2
  }

  get staffExecutives () {
    return this.executivesForReports?.executivesForReports
  }

  get executivesNumber () {
    if (!this.executivesForReports?.executivesForReports) return
    const executivesForReports = this.executivesForReports?.executivesForReports
    return executivesForReports?.length
  }
}
