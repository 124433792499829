
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { DetailByExecutive } from './detailByExecutive'
  import ExecutiveDetail from './Detail/ExecutiveDetail.vue'

@Component({
  components: {
    ExecutiveDetail,
  },
})
  export default class SalesByExecutive extends DetailByExecutive {
  @Prop() sales: Record<string, any>
  @Prop() salesTarget: Record<string, any>

  amount = 0
  target = 10
  cars = []
  executives = []

  // Methods
  getData () {
    if (
      !this.sales?.sales ||
      !this.salesTarget ||
      !this.staffExecutives) return
    const salesNumber = this.sales.sales.length
    if (salesNumber !== undefined) {
      this.amount = salesNumber
    }
    const cars = this.sales?.sales
    if (cars) {
      this.cars = [...cars].sort((prev, next) => {
        return -dayjs(prev.deliveryDate).diff(dayjs(next.deliveryDate))
      })
    }
    const salesTarget = this.salesTarget?.settings_process?.[0]?.config?.projectedUtility?.quantity
    if (salesTarget) {
      this.target = salesTarget
    }

    let executives = []
    if (cars) {
      cars.map(car => {
        let executive = {
          id: car.executive,
          name: car.executiveName,
        }
        if (!executive) {
          executive = {
            id: undefined,
            name: 'SIN EJECUTIVO',
          }
        }
        if (!executives.map(executive => executive.id).includes(executive.id)) {
          executives.push(executive)
        }
      })
    }

    if (this.staffExecutives) {
      this.staffExecutives.forEach(staff => {
        if (!executives.map(executive => executive.id).includes(staff.id_executive)) {
          executives.push({
            id: staff.id_executive,
            name: `${staff.first_name} ${staff.surname}`,
          })
        }
      })
    }

    this.executives = []
    executives = executives.sort((prev, next) => {
      if (prev.name > next.name) {
        return 1
      } else if (prev.name < next.name) {
        return -1
      }
      return 0
    })
    executives = executives.sort((prev, next) => {
      const staffIDs = this.staffExecutives.map(staff => staff.id_executive)
      if (staffIDs.includes(prev.id)) {
        if (staffIDs.includes(next.id)) {
          return 1
        } else {
          return -1
        }
      } else {
        return 1
      }
    })

    executives.forEach(executive => {
      const filtered = cars.filter(car => {
        return car.executive === executive.id
      })

      this.executives.push({
        executive,
        amount: filtered.length,
      })
    })
  }

  @Watch('sales', { immediate: true, deep: true })
  @Watch('salesTarget', { immediate: true, deep: true })
  @Watch('staffExecutives', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
