
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import dayjs from 'dayjs'

@Component({})
  export default class TwelveMonthGeneralDetail extends BaseChart {
  @Prop() purchases: Record<string, any>
  @Prop() sales: Record<string, any>
  @Prop() credits: Record<string, any>
  @Prop() dates: Record<string, any>
  counts = {
    purchases: [],
    sales: [],
    credits: [],
  }

  // Methods
  mounted () {
    this.getData()
    this.display = true
  }

  getData () {
    const purchases = this.purchases?.sales_stock_view_aggregate?.nodes
    const sales = this.sales?.sales_stock_aggregate?.nodes
    const credits = this.credits?.loans_evaluation_view_aggregate?.nodes

    if (!sales || !purchases || !credits) return
    this.counts = {
      purchases: this.months.dates.map(date =>
        purchases.filter(purchase => date.isSame(dayjs(this.convertUtc(purchase.stock_created)), 'month')).length),
      sales: this.months.dates.map(date =>
        sales.filter(sale => date.isSame(dayjs(this.convertUtc(sale.sold_date)), 'month')).length),
      credits: this.months.dates.map(date =>
        credits.filter(credit => date.isSame(dayjs(this.convertUtc(credit.signed_date)), 'month')).length),
    }

    this.series = [
      {
        name: 'Compras',
        color: '#6185DB',
        data: this.counts.purchases,
      },
      {
        name: 'Ventas',
        color: '#13CE66',
        data: this.counts.sales,
      },
      {
        name: 'Créditos',
        color: '#7D67BC',
        data: this.counts.credits,
      },
    ]

    this.chartOptions = {
      chart: {
        stacked: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: this.months.names,
      },
      yaxis: {
        min: 0,
        stepSize: 1,
        forceNiceScale: true,
        decimalsInFloat: 0,
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
    }
  }

  sumArray (arr: number[]) {
    let sum = 0
    arr.forEach(item => sum += item)
    return sum
  }

  // Getters
  get months () {
    const start = dayjs(this.dates._gte)
    const end = dayjs(this.dates._lte).startOf('month')

    const dates = []
    for (let i = start; i.isBefore(end); i = i.add(1, 'month')) {
      dates.push(i)
    }

    return {
      dates,
      names: dates.map(date => {
        return {
          '01': 'Ene',
          '02': 'Feb',
          '03': 'Mar',
          '04': 'Abr',
          '05': 'May',
          '06': 'Jun',
          '07': 'Jul',
          '08': 'Ago',
          '09': 'Sep',
          10: 'Oct',
          11: 'Nov',
          12: 'Dic',
        }[date.format('MM')]
      }),
    }
  }

  get watchData () {
    return {
      purchases: this.purchases,
      sales: this.sales,
      credits: this.credits,
    }
  }

  // Watchers
  @Watch('watchData', { immediate: true, deep: true })
  onDatesChange () {
    this.display = false
    this.getData()
    this.display = true
  }
  }
