
  import { Debounce } from '@/utils/decorators'
  import { deepCopy, fixPrice } from '@/utils/general'
  import dayjs from 'dayjs'
  import Vue from 'vue'
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'

@Component({})
  export default class PurchasesReport extends Vue {
  @Prop() reports: Record<string, any>;
  @Prop({ default: undefined, type: Number }) idExecutive
  reportsAttribute = []
  executives = []
  fixPrice = fixPrice
  dayjs = dayjs

  getData () {
    if (!this.reports?.unvalidated) return

    const { unvalidated, negotiation, process } = deepCopy(this.reports)
    const reports = [
      {
        title: 'Inspecciones',
        data: unvalidated.sort((prev, next) =>
          dayjs(prev.date).diff(dayjs(next.date), 'day')
        ),
      },
      {
        title: 'Negociaciones',
        data: negotiation.sort((prev, next) =>
          dayjs(prev.startDate).diff(dayjs(next.startDate), 'day')
        ),
      },
      {
        title: 'Compras en proceso',
        data: process.sort((prev, next) =>
          dayjs(prev.startDate).diff(dayjs(next.startDate), 'day')
        ),
      },
    ]

    if (!reports) return

    let executives = []
    reports.forEach(reportGroup => {
      reportGroup.data.forEach(report => {
        if (!executives.includes(report.executiveName)) {
          executives.push(report.executiveName)
        }
      })
    })
    executives = executives.filter(executive => executive !== undefined)
    this.executives = executives
    this.reportsAttribute = reports
  }

  filterByExecutive (reportGroup, executive, id?) {
    if (id) {
      const filterById = reportGroup.filter(report => report.executiveId === id)
      executive = filterById?.[0]?.executiveName
    }
    return reportGroup.filter(report => report.executiveName === executive)
  }

  getPriceByReport (report) {
    if (report.expectedPublicationAmount) {
      return report.expectedPublicationAmount
    }
    if (report.expectedPublicationPrice) {
      return report.expectedPublicationPrice
    }
    if (report.estimatedPublicationPrice) {
      return report.estimatedPublicationPrice
    }
    return 0
  }

  get totalInversions () {
    let count = 0
    let reports = this.reportsAttribute.map(report => report.data).flat()
    if (this.idExecutive) {
      reports = reports.filter(report => report.executiveId === this.idExecutive)
    }
    reports.forEach(report => (count += Number(report.agreedPrice)))
    return count
  }

  get totalUtility () {
    let count = 0
    const reports = this.reportsAttribute.map(report => report.data).flat()
    reports.forEach(report => (count += Number(this.getPriceByReport(report))))
    count -= this.totalInversions
    return count
  }

  get purchasesCount () {
    if (!this.reportsAttribute) {
      return
    }
    let count = 0
    if (!this.idExecutive) {
      this.reportsAttribute.forEach(reportGroup => (count += reportGroup.data.length))
    } else {
      this.reportsAttribute.map(group => group.data).forEach(reportGroup => {
        count += this.filterByExecutive(reportGroup, undefined, this.idExecutive).length
      })
    }
    return count
  }

  get dataW () {
    const { reports, idExecutive } = this
    return { reports, idExecutive }
  }

  @Watch('dataW', { immediate: true, deep: true })
  @Debounce(500)
  onReportsChange () {
    this.getData()
  }
  }
